<template>
  <div class="content block-el">
    <PageHeader :title="$t('admin.plan.edit')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="update">
            <card no-header footer>
              <template #body>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $t('name') }}
                      </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">
                        {{ $t('type') }}
                      </label>
                      <div class="col-sm-10">
                        <select
                          class="form-control"
                          :value="form.service"
                          disabled
                        >
                          <option value="sms">SMS</option>
                          <!-- <option value="omni">Omni Business</option> -->
                          <option value="omni">Chat</option>
                          <option value="whatsapp">Whatsapp API</option>
                          <option value="rcs">Google RCS</option>
                          <option value="email">E-Mail</option>
                          <option value="lookup">Lookup</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div v-if="form.service === 'omni'" class="col-6">
                    <div class="form-group row">
                      <div class="col-sm-2">{{ $tc('app.permission', 2) }}</div>
                      <div class="col-sm-10">
                        <div
                          v-for="(i, permission, index) in form.permissions"
                          :key="index"
                          class="
                            custom-control custom-checkbox
                            checkbox-primary
                            m-b-20
                          "
                        >
                          <input
                            v-model="form.permissions[permission]"
                            type="checkbox"
                            class="custom-control-input"
                            :id="permission"
                          />
                          <label
                            class="custom-control-label"
                            :for="permission"
                            >{{ $tc(`app.${permission}`, 2) }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #footer>
                <div class="form-group m-b-0 row">
                  <div class="col-sm-10">
                    <custom-button :loading="storing">{{ $t('app.save') }}</custom-button>
                  </div>
                </div>
              </template>
            </card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
  name: 'CreateNumber',
  components: {
    PageHeader,
    Card,
    CustomButton,
  },
  directives: {},
  data() {
    return {
      loading: false,
      storing: false,
      form: {
        name: '',
        service: '',
        permissions: {},
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('fetchAdminPlan', this.$route.params.planId)
        .then((response) => {
          this.form = response.data;
          this.pages = response.pages;
          this.loading = false;
        });
    },
    update() {
      this.storing = true;
      this.$store
        .dispatch('updateAdminPlans', {
          input: this.form,
          planId: this.$route.params.planId,
        })
        .then((response) => {
          this.$router.push({ name: 'AdminPlansIndex' });
        })
        .finally(() => {
          this.storing = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
